import type { ApiType } from '~/api/types'
import { DEV_SERVER_BASE_URL } from '~/constants'
import { env } from '~/env.mjs'

export const createRefreshRequest = async () => {
  const url = new URL(
    '/next-api/auth/refresh',
    process.env.NODE_ENV === 'development'
      ? DEV_SERVER_BASE_URL
      : env.NEXT_PUBLIC_BASE_URL,
  )

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (!response.ok) {
    throw response
  }

  return response.json() as Promise<ApiType<'TokenSchema'>>
}
