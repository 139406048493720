// @ts-check
import { createEnv } from '@t3-oss/env-nextjs'
import { z } from 'zod'

export const env = createEnv({
  server: {
    S3_BUCKET_URL: z.string().url(),
  },
  client: {
    NEXT_PUBLIC_BASE_URL: z.string().url(),
    NEXT_PUBLIC_AUTH_BASE_URL: z.string().url(),
    NEXT_PUBLIC_MANAGE_BASE_URL: z.string().url(),
    NEXT_PUBLIC_ANALYTICS_BASE_URL: z.string().url(),
    NEXT_PUBLIC_ACCESS_SERVICE_BASE_URL: z.string().url(),
    NEXT_PUBLIC_STATS_BOT_URL: z.string().url(),
    NEXT_PUBLIC_PRIZMA_TOOLS_URL: z.string().url(),
    NEXT_PUBLIC_PRIZMA_TOOLS_BOT_LOGIN: z.string(),
    NEXT_PUBLIC_PRODUCTION: z.coerce.number(),
    NEXT_PUBLIC_YANDEX_METRIKA_KEY: z.coerce.number(),
    NEXT_PUBLIC_SALES_SERVICE_BASE_URL: z.string().url(),
    NEXT_PUBLIC_LINKS_SERVICE_BASE_URL: z.string().url(),
  },
  experimental__runtimeEnv: {
    NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
    NEXT_PUBLIC_AUTH_BASE_URL: process.env.NEXT_PUBLIC_AUTH_BASE_URL,
    NEXT_PUBLIC_MANAGE_BASE_URL: process.env.NEXT_PUBLIC_MANAGE_BASE_URL,
    NEXT_PUBLIC_ANALYTICS_BASE_URL: process.env.NEXT_PUBLIC_ANALYTICS_BASE_URL,
    NEXT_PUBLIC_ACCESS_SERVICE_BASE_URL:
      process.env.NEXT_PUBLIC_ACCESS_SERVICE_BASE_URL,
    NEXT_PUBLIC_STATS_BOT_URL: process.env.NEXT_PUBLIC_STATS_BOT_URL,
    NEXT_PUBLIC_PRIZMA_TOOLS_URL: process.env.NEXT_PUBLIC_PRIZMA_TOOLS_URL,
    NEXT_PUBLIC_PRIZMA_TOOLS_BOT_LOGIN:
      process.env.NEXT_PUBLIC_PRIZMA_TOOLS_BOT_LOGIN,
    NEXT_PUBLIC_PRODUCTION: process.env.NEXT_PUBLIC_PRODUCTION,
    NEXT_PUBLIC_YANDEX_METRIKA_KEY: process.env.NEXT_PUBLIC_YANDEX_METRIKA_KEY,
    NEXT_PUBLIC_SALES_SERVICE_BASE_URL:
      process.env.NEXT_PUBLIC_SALES_SERVICE_BASE_URL,
    NEXT_PUBLIC_LINKS_SERVICE_BASE_URL:
      process.env.NEXT_PUBLIC_LINKS_SERVICE_BASE_URL,
  },
})
