import { createMutation } from '@farfetched/core'
import type { EventPayload } from 'effector'
import { createEvent, sample } from 'effector'
import { toggleFavoriteChannelBaseFx } from '~/api/manage.effects'
import type { ApiType } from '~/api/types'

export const toggleFavoriteChannelClicked =
  createEvent<Pick<ApiType<'ChannelSchema'>['channel'], 'id'>>()

export const toggleFavoriteChannelMutation = createMutation({
  effect: toggleFavoriteChannelBaseFx,
})

sample({
  clock: toggleFavoriteChannelClicked,
  fn: ({ id }) =>
    ({
      params: {
        path: {
          channel_id: id,
        },
      },
    }) satisfies EventPayload<typeof toggleFavoriteChannelMutation.start>,
  target: toggleFavoriteChannelMutation.start,
})
