export const ACCESS_TOKEN_STORAGE_KEY = 'access_token'
export const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token'
export const DEV_SERVER_BASE_URL = 'http://localhost:3000'
export const INVITE_CODE_STORAGE_KEY = 'inviteCode'
export const APP_START_DATE = new Date(2023, 5, 1)

export const DEFAULT_INPUT_DEBOUNCE_TIME = 500

export const EMPTY_INVITE_LINK_HASH = 'empty'

export const SUPPORT_TELEGRAM_USERNAME = 'artem_prizma'
export const SUPPORT_SITE_URL = 'https://prizma.tools'
